// React imports

// Remix imports

// Third party imports

// Generated imports

// App imports
import useGlobal from '~/hooks/useGlobal'

const useLang = () => {
  const global = useGlobal()

  if (!global) {
    return 'en'
  }

  return global.lang
}

export default useLang
